import React from 'react';
import Image from 'next/image';

import coastRealty from './images/coast-realty.png';
import elders from './images/elders.png';
import harcourts from './images/harcourts-international.png';
import lgHooker from './images/lg-hooker.png';
import loweCo from './images/lowe-co.png';
import mcGrath from './images/mc-grath.png';
import rayWhite from './images/ray-white.png';
import theAgency from './images/the-agency.png';

import styles from './AgencyLogosMarquee.module.scss';

const imageList = [coastRealty, elders, harcourts, lgHooker, loweCo, mcGrath, rayWhite, theAgency];

const AgencyLogosMarquee = () => (
    <div className="h-full w-full overflow-hidden py-20">
        <div className={styles.marqueeContainer}>
            {Array.from({ length: 4 }).map((_, index) => (
                <div className={styles.marqueeGroup} key={index}>
                    {imageList.map((imageUrl) => (
                        <div className="relative h-11 w-full" key={imageUrl}>
                            <Image layout="fill" className="object-contain" src={imageUrl} />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    </div>
);
export default AgencyLogosMarquee;
