import React from 'react';
import { Modal } from 'antd';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { PackageType } from '~/types/create-agent-form-type';
import PrePortalPaywall from '~/components/PrePortalPaywall';

type Props = {
    isVisible: boolean;
    onCancel: () => void;
    onSelectPackage?: (selectedPackage: PackageType) => void;
};

const PaywallModal = ({ isVisible, onCancel, onSelectPackage = () => {} }: Props) => {
    return (
        <Modal
            visible={isVisible}
            footer={null}
            onCancel={onCancel}
            width={1152}
            closeIcon={<FA icon={faTimes} />}
            centered
            className="waitListModalStlye"
            maskStyle={{ backgroundColor: 'rgb(17 24 39 / 0.7)', backdropFilter: 'blur(8px)' }}
        >
            <PrePortalPaywall onSelectPackage={onSelectPackage} />
        </Modal>
    );
};

export default PaywallModal;
