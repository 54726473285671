import React, { useState } from 'react';
import Link from 'next/link';
import { Modal } from 'antd';

import { PackageType } from '~/types/create-agent-form-type';
import { packages } from '~/constants/pre-portal-package';

import ExistingAgentSearchModal from '~/pages/ExistingAgentSearchModal';

import PrePortalPreRegisterModal from '~/components/PrePortalPreRegisterModal';
import ReactPlayer from '~/components/ReactPlayer';
import PaywallModal from '~/components/PaywallModal';

import styles from './HomePageHero.module.scss';

const PROPERTY_MARKET_VIDEO = 'https://vimeo.com/991330228';

type Props = {
    propertyTodayCount?: number;
};

const DemoButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Modal
                style={{ top: 20 }}
                bodyStyle={{
                    height: '82vh',
                    overflow: 'auto',
                }}
                visible={isOpen}
                destroyOnClose
                onCancel={() => setIsOpen(false)}
                footer={null}
                width={800}
                maskClosable
            >
                <iframe
                    width="100%"
                    height="100%"
                    src="https://realty.pipedrive.com/scheduler/WJ2qDWfJ/realtycomau-demo"
                    title="Realty.com.au Marketplace Demo"
                />
            </Modal>
            <button
                onClick={() => setIsOpen(true)}
                className="w-fit whitespace-nowrap rounded-lg bg-blue-500 px-20 py-3 text-base font-semibold text-white duration-300 hover:bg-blue-400"
            >
                Demo
            </button>
        </>
    );
};

const HomePageHero = ({ propertyTodayCount }: Props) => {
    const [isOpenPaywallModal, setIsOpenPaywallModal] = useState(false);
    const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
    const [isOpenPreRegisterModal, setIsOpenPreRegisterModal] = useState(false);

    const [selectedPackage, setSelectedPackage] = useState<PackageType | null>(null);

    const handleSelectPackage = (selectedPackageType: PackageType) => {
        setSelectedPackage({ ...selectedPackageType });

        if (selectedPackageType?.name === packages.PRO.name) {
            setIsOpenPreRegisterModal(true);
        } else {
            setIsOpenSearchModal(true);
        }

        setIsOpenPaywallModal(false);
    };

    // let isSM = useMatchMedia('(min-width: 640px)');

    return (
        <>
            <PaywallModal
                isVisible={isOpenPaywallModal}
                onCancel={() => setIsOpenPaywallModal(false)}
                onSelectPackage={handleSelectPackage}
            />
            <ExistingAgentSearchModal
                isVisible={isOpenSearchModal}
                onCancel={() => setIsOpenSearchModal(false)}
                selectedPackage={selectedPackage}
            />
            <PrePortalPreRegisterModal
                isVisible={isOpenPreRegisterModal}
                onCancel={() => setIsOpenPreRegisterModal(false)}
                selectedPackage={selectedPackage}
            />

            <section className="flex h-full w-full justify-center bg-slate-50">
                <div className="w-full max-w-screen-xl">
                    <div className="flex h-full flex-col lg:flex-row">
                        <div
                            className={`flex w-full flex-col items-center gap-y-4 px-5
                                        py-8 sm:px-16 lg:w-1/2 lg:items-start lg:py-24`}
                        >
                            <Link href="/discover">
                                <a
                                    target="_blank"
                                    className={`block whitespace-nowrap pb-4 font-medium 
                                    text-gray-900 hover:text-gray-900 sm:hidden`}
                                >
                                    New Listings Today: {propertyTodayCount?.toLocaleString()}
                                </a>
                            </Link>

                            <p
                                className={`text-center text-2xl leading-snug text-gray-900
                             md:text-[2.5rem] lg:text-start`}
                            >
                                <span className="whitespace-nowrap">Property Marketplace </span>
                                <br />
                                <span className="font-semibold">that connects buying and selling agents</span>
                                {/* <span className="font-semibold">For Buyers Agents & Professionals</span> */}
                            </p>
                            {/* <Link href={'/sign-up'}>
                                <a className="w-fit whitespace-nowrap rounded-lg bg-blue-500 px-24 py-4 font-bold
                                 text-white duration-300 hover:text-white/80 md:text-lg lg:px-32">
                                    Join
                                </a>
                            </Link> */}
                            <div>
                                <DemoButton />
                                <p className="pt-4 text-center text-sm">
                                    or{' '}
                                    <a
                                        href="https://buyer.realty.com.au/login"
                                        className="font-semibold text-black underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Login
                                    </a>{' '}
                                    to your account
                                </p>
                            </div>
                        </div>
                        <div className="relative flex h-full w-full flex-1 items-center">
                            <div
                                className={`relative flex h-full min-h-[20.125rem] w-full items-center py-4 lg:px-16 
                                ${styles.HeroBackgroundImage}`}
                            >
                                {/* <Image
                                    src={illustrationImage}
                                    width={isSM ? 693 : 414}
                                    height={isSM ? 433 : 333}
                                    layout="responsive"
                                    className="h-full w-full object-contain"
                                    priority
                                /> */}
                                <ReactPlayer
                                    // style={{ backgroundImage: `url(${forBackground})` }}
                                    controls
                                    playing
                                    muted
                                    url={PROPERTY_MARKET_VIDEO}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomePageHero;
