import React from 'react';
import Script from 'next/script';

const WebinarGenerateMoreBuyerBriefs = () => (
    <>
        <noscript>
            <div
                style={{
                    maxWidth: 300,
                    marginTop: '1em',
                    padding: '0.25em 0 1em',
                    borderRadius: 10,
                    boxShadow: '0 2px 10px rgb(0 0 0 / 20%)',
                    fontFamily: 'sans-serif',
                }}
            >
                <h3 className="text-center" style={{ fontSize: 18 }}>
                    Realty&apos;s Leads Module
                </h3>
                <a
                    className="ewebinar__RegisterButton__Wrap ewebinar--ltr"
                    href="https://realty.ewebinar.com/webinar/realtys-leads-module-16804"
                    style={{ textDecoration: 'none' }}
                >
                    <button
                        className="ewebinar__Widget ewebinar__RegisterButton"
                        type="button"
                        style={{
                            borderRadius: 50,
                            background: '#cf3939ff',
                            color: '#fff',
                        }}
                    >
                        <span className="ewebinar__ButtonText" style={{ whiteSpace: 'nowrap' }}>
                            Watch the latest webinar!
                        </span>
                    </button>
                </a>

                <style jsx>{`
                    .ewebinar--rtl {
                        direction: rtl;
                    }

                    .ewebinar__Widget select {
                        display: flex;
                    }

                    .ewebinar__Widget * {
                        box-sizing: border-box;
                    }

                    .ewebinar__RegisterButton {
                        font-family: inherit;
                        box-sizing: border-box;
                        font-family: inherit;
                        position: relative;
                        display: inline-block;
                        padding: 0.5em 2em;
                        cursor: pointer;
                        border-width: 0px;
                        outline: none;
                        transition: all 0.2s;
                        font-weight: 500;
                        font-size: inherit;
                        box-sizing: border-box;
                        margin: 0;
                    }

                    .ewebinar__RegisterButton.loaded .ewebinar__Dots {
                        display: none !important;
                    }

                    .ewebinar__RegisterButton.loaded .ewebinar__ButtonText {
                        opacity: 1 !important;
                    }

                    .ewebinar__RegisterButton * {
                        font-family: inherit;
                    }

                    .ewebinar__RegisterButton:hover {
                        transform: scale(1.02);
                    }

                    .ewebinar__RegisterButton:active {
                        box-shadow: none;
                    }

                    .ewebinar__RegisterButton .ewebinar__ButtonText {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline;
                        vertical-align: baseline !important;
                    }

                    @media only screen and (max-width: 614px) {
                        .ewebinar__RegisterButton {
                            max-width: 320px !important;
                            min-height: 42px !important;
                            width: 100% !important;
                        }
                    }

                    .ewebinar__powered_by_hidden {
                        visibility: hidden;
                        height: 0px !important;
                        margin: 0px !important;
                    }

                    .ewebinar__powered_by {
                        display: flex;
                        align-items: baseline;
                        justify-content: center;
                        text-decoration: none;
                        height: 9px;
                    }

                    .ewebinar__FloatIn .ewebinar__powered_by_text,
                    span.ewebinar__powered_by_text {
                        color: #9aadaf;
                        white-space: nowrap;
                        font-family: sans-serif !important;
                        font-weight: 400;
                        font-size: 9px;
                        line-height: 9px;
                    }

                    .ewebinar__powered_by_logo {
                        height: 9px;
                    }

                    a.ewebinar__RegForm__powered_by {
                        width: auto;
                    }

                    @media (max-width: 768px) {
                        a.ewebinar__RegForm__powered_by {
                            display: none;
                        }
                    }

                    .ewebinar__Dots {
                        opacity: 1;
                        animation: 1.5s linear 0s normal forwards 1.5 delayin;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    button .ewebinar__Dots {
                        background: #cf3939ff;
                        border-radius: 50px;
                    }

                    .ewebinar__LoadingDot {
                        height: 0.5em;
                        width: 0.5em;
                        border-radius: 100%;
                        display: inline-block;
                        animation: 1.2s ewebinar-loading-dot ease-in-out infinite;
                    }

                    .ewebinar__LoadingDot:nth-of-type(2) {
                        animation-delay: 0.15s;
                        margin-left: 0.5em;
                    }

                    .ewebinar__LoadingDot:nth-of-type(3) {
                        animation-delay: 0.25s;
                        margin-left: 0.5em;
                    }

                    @keyframes delayin {
                        0% {
                            opacity: 0;
                        }
                        66% {
                            opacity: 0;
                        }
                        100% {
                            opacity: 1;
                        }
                    }

                    @keyframes ewebinar-loading-dot {
                        30% {
                            transform: translateY(-35%);
                            opacity: 0.3;
                        }
                        60% {
                            transform: translateY(0%);
                            opacity: 0.8;
                        }
                    }
                `}</style>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '1em',
                    }}
                >
                    <a
                        style={{
                            color: '#000',
                            fontSize: 11,
                        }}
                        href="https://ewebinar.com?utm_source=widget"
                    >
                        Best on-demand webinar software platform
                    </a>
                </div>
            </div>
        </noscript>
        <Script id="webinar-button" strategy="afterInteractive">
            {/* eslint-disable-next-line max-len */}
            {`(function (w,d,s,o,f,js,fjs) { w['eWidget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; if(d.getElementById(o)) return; js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', '_ew', 'https://app.ewebinar.com/widget.js'));_ew('init', {"root":"w1724196384567","isReview":false,"mode":"public","openInPopup":false,"for":"Registration","type":"FixedBar","source":"","url":"https://realty.ewebinar.com/webinar/realtys-leads-module-16804","shortUrl":"https://realty.ewebinar.com/webinar/16804","sessions":[],"formType":"LatestForm","ewebinar":{"title":"Realty's%20Leads%20Module","borderRadius":50,"primaryColor":"#3b82f6ff","readableColor":"#ffffff","actionColor":"#cf3939ff","readableActionColor":"#ffffff","readableOnWhiteColor":"#3b82f6ff","language":"en"},"showGdprBanner":false,"gdprBannerMode":"Off","gdprBannerText":"","hideBranding":false,"teamId":"1240","carouselId":"","isWebinarRegPage":false,"isMobile":false,"initialized":false,"button":{"btnText":"Watch the latest webinar!","showButtonTimer":true,"buttonPrimaryColor":"#cf3939ff","buttonReadableColor":"#ffffff","align":"Center","isFullWidth":false},"fixedBar":{"showTitle":false}});window.ewInit && window.ewInit();`}
        </Script>
    </>
);

export default WebinarGenerateMoreBuyerBriefs;
