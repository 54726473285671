import React from 'react';
import { NextSeo } from 'next-seo';
import withApolloProvider from '~/lib/apollo/with-apollo-provider';
import homeImage from '~/assets/home.png';
import NewPublicLayout from '~/layouts/NewPublicLayout';
import HomePageHero from '~/components/HomePageHero';
import HomePageBanner from '~/components/HomePageBanner';
import PipedriveChat from '~/components/PipedriveChat';
import AgencyLogosMarquee from '~/components/AgencyLogosMarquee';

import { useQuery } from '@apollo/client';
import DailyNewCurrentPropertyCount from '~/queries/DailyNewCurrentPropertyCount.graphql';
import WebinarGenerateMoreBuyerBriefs from '~/components/WebinarGenerateMoreBuyerBriefs';

const IndexPage = () => {
    // const title = `Property Marketplace for Buyers Agents & Professionals | Realty.com.au`;
    const title = `Property Marketplace that connects buying and selling agents | Realty.com.au`;
    const metaDescription =
        'Access pre-portal, passive and private properties. AI-powered alerts to save your time. Curated inventory for investors. Buyers agents and professionals';

    const { data } = useQuery(DailyNewCurrentPropertyCount, { ssr: false });
    const { adminPropertyStatistics } = data || {};
    const { dailyNewCurrentPropertyCount } = adminPropertyStatistics || {};

    const totalCurrentPropertyDaily = dailyNewCurrentPropertyCount?.at(-1)?.total ?? 0;

    return (
        <>
            <NextSeo
                title={title}
                description={metaDescription}
                canonical="https://www.realty.com.au"
                openGraph={{
                    type: 'product',
                    title,
                    description: metaDescription,
                    locale: 'en_US',
                    url: `https://www.realty.com.au`,
                    images: [
                        {
                            url: homeImage,
                            width: 1200,
                            height: 628,
                            alt: 'Property Marketplace that connects buying and selling agents | Realty.com.au',
                        },
                    ],
                }}
            />
            <PipedriveChat companyId={4209319} playbookUuid="ee9d5577-fa3c-413c-890b-a0dca765aa99" />
            <NewPublicLayout propertyTodayCount={totalCurrentPropertyDaily}>
                <HomePageHero propertyTodayCount={totalCurrentPropertyDaily} />
                <HomePageBanner />
                <AgencyLogosMarquee />
            </NewPublicLayout>
            <WebinarGenerateMoreBuyerBriefs />
        </>
    );
};

export default withApolloProvider(IndexPage);
