import React from 'react';
import Image from 'next/image';
import styles from './HomePageBanner.module.scss';

import homeIcon from '~/assets/home-icon.png';
import alertsIcon from '~/assets//alerts.png';
import inventoryIcon from '~/assets//inventory.png';
import buyerIcon from '~/assets//buyer.png';

import useWindowSize from '~/utils/useWindowSize';

type bannerItemType = {
    imageUrl: string;
    description: string;
    height: number;
    mobileHeight: number;
    width: number;
    mobileWidth: number;
};

const bannerItems: bannerItemType[] = [
    {
        imageUrl: homeIcon,
        height: 59,
        width: 68,
        mobileWidth: 45,
        mobileHeight: 39,
        description: 'Access pre-portal, passive and private properties',
    },

    {
        imageUrl: alertsIcon,
        height: 63,
        width: 46,
        mobileWidth: 28,
        mobileHeight: 39,
        description: 'AI-powered alerts to save you time',
    },
    {
        imageUrl: inventoryIcon,
        height: 60,
        width: 57,
        mobileHeight: 39,
        mobileWidth: 36,
        description: 'Curated inventory for investors',
    },
    {
        imageUrl: buyerIcon,
        height: 60,
        width: 56,
        mobileHeight: 39,
        mobileWidth: 36,
        description: 'Buyers agents and professionals',
    },
];

const HomePageBanner = () => {
    const { width: screenWidth } = useWindowSize();
    return (
        <div className={`flex w-full justify-center ${styles.gradientBlueBg}`}>
            <div className={`flex w-full max-w-screen-xl items-center px-6 py-11 sm:px-16`}>
                <div className="grid w-full grid-cols-2 gap-4 sm:gap-14 lg:grid-cols-4">
                    {/* <div className="flex h-full w-full"> */}
                    {bannerItems.map(({ imageUrl, description, height, width, mobileHeight, mobileWidth }) => {
                        const imgHeight = screenWidth && screenWidth < 640 ? mobileHeight : height;
                        const imgWidth = screenWidth && screenWidth < 640 ? mobileWidth : width;

                        return (
                            <div
                                key={imageUrl}
                                className="flex flex-col gap-4 rounded-2xl bg-white p-4 sm:flex-row"
                                style={{
                                    boxShadow: 'rgba(0, 0, 0, 0.02)',
                                }}
                            >
                                <div className="relative flex items-center">
                                    <Image src={imageUrl} alt={description} height={imgHeight} width={imgWidth} />
                                </div>
                                <p className="m-auto w-full flex-1 text-xs text-slate-500 sm:text-sm">{description}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default HomePageBanner;
