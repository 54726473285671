import React from 'react';
import dynamic from 'next/dynamic';
import { ReactPlayerProps } from 'react-player/lazy';
import clsx from 'clsx';
import styles from './ReactPlayer.module.css';

// https://github.com/cookpete/react-player/issues/1436

const DynamicReactPlayer = dynamic(() => import('react-player/lazy'), { loading: () => <p>Loading...</p> });

type Props = {
    containerClassName?: string;
    playerRatio?: string /* Player ratio: 100 / (1280 / 720) */;
} & ReactPlayerProps;

// eslint-disable-next-line react/display-name
const ReactPlayer3: React.FC<Props> = (props) => {
    const { containerClassName, className, playerRatio = '56.25%', ...rest } = props;
    return (
        <div
            className={clsx(containerClassName, styles.playerWrapper)}
            style={{ ...(playerRatio ? { paddingTop: playerRatio } : {}) }}
        >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <DynamicReactPlayer {...rest} width="100%" height="100%" className={clsx(className, styles.reactPlayer)} />
        </div>
    );
};

export default ReactPlayer3;
